export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'dashboard-home',
    resource: 'LoggedIn',
    action: 'read',
  },
  {
    icon: 'LayersIcon',
    title: 'Preventivatore',
    route: 'estimator-wizard',
    resource: 'Preventivatore',
    action: 'read',

  },
  {
    icon: 'LayersIcon',
    title: 'Preventivi',
    route: 'quotes-list',
    resource: 'Preventivatore',
    action: 'read',

  },
  {
    header: 'anagrafiche',
    resource: 'WORKS',
    action: 'read',
  },
  /* {
    icon: 'Share2Icon',
    title: 'Rete Vendita',
    route: 'sales-network',
    resource: 'ReteVendita',
    action: 'read',
  }, */
  {
    icon: 'GlobeIcon',
    title: 'Compagnie',
    route: 'companylist',
    resource: 'Compagnie',
    action: 'read',
  },
  {
    icon: 'BriefcaseIcon',
    title: 'Dealer',
    route: 'agencylist',
    resource: 'Dealer',
    action: 'read',
  },
  {
    icon: 'ShieldIcon',
    title: 'Prodotti Assicurativi',
    route: 'productlist',
    resource: 'Prodotti',
    action: 'read',

  },
  {
    icon: 'GridIcon',
    title: 'Pacchetti Assicurativi',
    route: 'bundlelist',
    resource: 'Prodotti',
    action: 'read',

  },
  {
    icon: 'TargetIcon',
    title: 'Programmi',
    route: 'programlist',
    resource: 'Prodotti',
    action: 'read',

  },
  {
    icon: 'MapPinIcon',
    title: 'Zone di Rischio',
    route: 'zone',
    resource: 'Zone',
    action: 'read',

  },
  // {
  //   icon: 'TruckIcon',
  //   title: 'Veicoli',
  //   route: 'companies',
  //   resource: 'Veicoli',
  //   action: 'read',

  // },
  // {
  //   icon: 'DownloadCloudIcon',
  //   title: 'Clienti',
  //   route: 'companies',
  //   resource: 'Clienti',
  //   action: 'read',

  // },
  {
    header: 'Amministrazione',
    resource: 'Utenti',
    action: 'read',
  },
  {
    title: 'Utenti',
    icon: 'UserIcon',
    children: [
      {
        title: 'Lista Utenti',
        route: 'japps-users-list',
        resource: 'Utenti',
        icon: 'UserCheckIcon',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ruoli & Permessi',
    icon: 'ShieldIcon',
    children: [
      {
        title: 'Ruoli',
        route: 'japps-roles-list',
        resource: 'Utenti',
        icon: 'ShieldIcon',
        action: 'read',
      },
    ],
  },
  {
    title: 'Impostazioni',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Impostazioni generali',
        route: 'jpages-app-setting',
        icon: 'SettingsIcon',
      },
      {
        title: 'Impostazioni Dealer',
        route: 'portal-configs',
        icon: 'SettingsIcon',
      },
    ],
  },
]
