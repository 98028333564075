<template>

  <component
    :is="depth == 0 ? 'b-container' : 'div'"
    :key="salesNode.id"
  >
    <b-row
      v-if="depth > 0"
      class="mb-1"
    >
      <b-col
        md="4"
        :style="indentCss"
      >
        <b-link
          :to="{ name: 'sales-network-user', params: { id: salesNode.id_user } }"
          style="color: #fff"
          :class="`${getUserBackColor()} pl-1 font-weight-bold d-block text-nowrap`"
        >
          {{ !salesNode.user ? "--" : salesNode.user.fullName }} <span class="small">({{ salesNode.id_user }})</span>
        </b-link>
      </b-col>

      <b-col
        md="4"
      >
        {{ !salesNode.user || !salesNode.user.roles ? "--" : salesNode.user.roles.name }}
        <span
          v-if="salesNode.user.DealerID && agencies.length>0"
          class="small"
        >
          ({{ getAgencyName(salesNode.user.DealerID) }})
        </span>
      </b-col>

      <b-col
        v-if="salesNode.user && salesNode.user.roles && salesNode.user.roles.name!='DEALER VENDITORE'"
        md="4"
        class="text-right"
      >
        <!--  <b-col
        md="4"
        class="text-right"
        > -->

        <!--
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            v-if="$can('write', 'Utenti')"
            :to="{ name: 'japps-users-edit', params: { id: salesNode.id_user } }"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('delete', 'Utenti')"
            @click="onRemoveChild(salesNode.id_user)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
        -->

        <b-link
          v-if="$can('delete', 'Utenti')"
          class="align-middle ml-50"
          @click="onOpenEditNetworkChildren(salesNode, parentNode)"
        >
          <feather-icon icon="MoveIcon" />
          <span class="">Move</span>
        </b-link>

        <b-link
          v-if="$can('delete', 'Utenti')"
          class="align-middle ml-50"
          @click="onDeleteNetworkChildren(salesNode, parentNode)"
        >
          <feather-icon icon="UserMinusIcon" />
          <span class="align-middle ml-50">Remove</span>
        </b-link>

      </b-col>
    </b-row>
    <tree-menu
      v-for="child in salesNode.children"
      :key="`folder_${child.id}`"
      :sales-node="child"
      :parent-node="salesNode"
      :fields="fields"
      :agencies="agencies"
      :depth="depth + 1"
      @onDeleteNetworkChildren="onDeleteNetworkChildren"
      @onOpenEditNetworkChildren="onOpenEditNetworkChildren"
    />
  </component>

  <!-- </component> -->
</template>
<script>
import {
  BDropdown, BDropdownItem, BRow, BCol, BContainerFluid, BContainer, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'

export default {
  name: 'TreeMenu',
  components: {
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainerFluid,
    BContainer,
    BLink,
    // BTable,
    // BTr,
    // BTh,
    // BTbody,
  },
  props: {
    salesNode: {
      type: Object,
      required: true,
      default() { return null },
    },
    parentNode: {
      type: Object,
      required: true,
      default() { return null },
    },
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    depth: {
      type: Number,
      required: true,
      default: () => 0,
    },
    agencies: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    indent() {
      return `pl-${this.depth}`
    },
    indentCss() {
      // console.log((this.depth - 1) * 30)
      return { paddingLeft: `${(this.depth - 1) * 30}px` }
    },
  },
  setup() {
  },
  methods: {
    getAgencyName(agencyId) {
      const agency = this.agencies // .find(x => x.Id == agencyId)
        .find(x => {
          const res = x.Id == agencyId
          // console.log(`${x.Id}=>${agencyId}`)
          return res
        })
      return agency ? agency.company : ""
    },
    onDeleteNetworkChildren(salesNode, salesParentNode) {
      this.$emit('onDeleteNetworkChildren', salesNode, salesParentNode)
    },
    onOpenEditNetworkChildren(idNode, idChild) {
      this.$emit('onOpenEditNetworkChildren', idNode, idChild)
    },
    getUserBackColor() {
      let bgColorClass = ""
      switch (this.depth) {
      case 1:
        bgColorClass = `bg-primary bg-lighten-1`
        break
      case 2:
        bgColorClass = `bg-info bg-lighten-1`
        break
      case 3:
        bgColorClass = `bg-success bg-lighten-1`
        break
      case 4:
        bgColorClass = `bg-warning bg-lighten-1`
        break
      default:
        bgColorClass = ""
        break
      }
      return bgColorClass
    },
  },
}
</script>
