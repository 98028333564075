<template>

  <!-- Table Container Card -->
  <b-card
    title="Zone di Rischio"
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <label>Entries</label> -->
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            v-if="productIdRef"
            variant="primary"
            :to="{ name: 'new-zona', params: { id: productIdRef }}"
          >
            Nuovo
          </b-button>
          <b-button
            v-else
            variant="primary"
            :to="{ name: 'new-zona', params: { id: -1 }}"
          >
            Nuovo
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Cerca..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refProgramListTable"
      :items="list"
      responsive
      :fields="tableColumns"
      primary-key="Id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <template #head(name)>
        <feather-icon
          icon="MapPinIcon"
          class="mx-auto"
        /> Descrizione
      </template>
      <template #head(product)>
        <feather-icon
          icon="ShieldIcon"
          class="mx-auto"
        /> Prodotto
      </template>
      <template #head(actions)>
        <feather-icon
          icon="ZapIcon"
          class="mx-auto"
        /> Azioni
      </template>

      <!-- Column: Id -->
      <template #cell(name)="data">
        <b-link
          :to="{ name: 'edit-zona', params: { id: data.item.Id }}"
          class="font-weight-bold"
        >
          {{ data.item.name }}
        </b-link>
      </template>

      <!-- Column: Name -->
      <template #cell(product)="data">
        <b-link
          :to="{ name: 'add-edit-product', params: { id: data.item.product.Id }}"
        >
          {{ data.item.product.Name }}
        </b-link>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <!-- <b-link
            :to="{ name: 'edit-zona', params: { id: data.item.Id }}"
          >

            <feather-icon
              :id="`program-row-${data.item.Id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
            />
            <b-tooltip
              title="Modifica"
              :target="`program-row-${data.item.Id}-edit-icon`"
            />
          </b-link> -->

          <feather-icon
            :id="`program-row-${data.item.Id}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="confirmDeleteElementList(data.item)"
          />
          <b-tooltip
            title="Elimina"
            :target="`program-row-${data.item.Id}-delete-icon`"
          />

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          />

        </b-col>

      </b-row>
    </div>
    <!-- <program-add-modal
      indexref="-1"
    /> -->
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BPagination, BTooltip,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  onUnmounted, watch, onMounted, ref, computed,
} from '@vue/composition-api'

import store from '@/store'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
// import ProgramAddModal from './ProgramAddModal.vue'
import zonaStoreModule from './zonaStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    BTooltip,

    vSelect,
    // ProgramAddModal,
  },
  props: {
    productId: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      // options:[],
      // list: [],
      // currentPage: 1,
      // totalElements: 0,
      // perPageOptions: [10],
      tableColumns: [
        { key: 'name', label: 'Descrizione', sortable: true },
        { key: 'product', label: 'Prodotto', sortable: false },
        { key: 'actions', label: 'Azioni' },
      ],
      // perPage: 10,
      // sortBy: "",
      // isSortDirDesc: false,
      // dataMeta: {},

    }
  },
  // watch: {
  //   searchQuery() {
  //     this.fetchData()
  //   },
  //   currentPage() {
  //     this.fetchData()
  //   },
  // },
  // mounted() {
  //   this.fetchData()
  // },
  methods: {
    // fetchData() {
    //   zonaStoreModule.actions.fetchList(null, {
    //     q: this.searchQuery,
    //     perPage: this.perPage,
    //     page: this.currentPage,
    //     sortBy: (this.sortBy),
    //     sortDesc: this.sortDesc,
    //     productId: this.productId,
    //   }).then(resp => {
    //     this.list = resp.data
    //   }).catch(eer => {
    //     console.log("EEEEE", eer)
    //   })
    // },
    confirmDeleteElementList(item) {
      this.$swal({
        title: 'Attenzione!',
        text: `Sicuro di volere eliminare ${item.name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, elimina',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        async preConfirm() {
          try {
            await zonaStoreModule.actions.delete(null, item.Id)
          } catch (err) {
            console.log(err)
            return null
          }
        },

      }).then(result => {
        if (result.value) {
          this.fetchData()
          this.$swal({
            icon: 'success',
            title: 'Eliminato!',
            text: 'Elemento eliminato con successo',
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Errore!',
            text: 'Si è verificato un errore. Programma non eliminato',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      }).catch(err => {
        this.$swal({
          title: 'Errore!',
          text: 'Si è verificato un errore. Programma non eliminato',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
  },
  setup(props) {
    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-program'

    // Register module
    if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, zonaStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })
    const productIdRef = ref(null)
    const list = ref([])
    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('')
    const isSortDirDesc = ref(false)
    const totalElements = ref(0)
    const fetchData = () => {
      console.log(props.productId)
      console.log(productIdRef.value)
      zonaStoreModule.actions.fetchList(null, {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        productId: productIdRef.value ?? '',
      }).then(resp => {
        list.value = resp.data.Zone
        totalElements.value = resp.data.total
      }).catch(eer => {
        console.log("EEEEE", eer)
      })
    }

    const dataMeta = computed(() => {
      const localItemsCount = list.value ? list.value.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalElements.value,
      }
    })
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    if (routeParams.value.id) {
      productIdRef.value = routeParams.value.id
    }

    watch(routeParams, () => {
      try {
        if (routeParams.value.id) {
          productIdRef.value = routeParams.value.id
        }
      // eslint-disable-next-line no-empty
      } catch (error) {
      }
    })

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc, productIdRef], () => {
      fetchData()
    })
    onMounted(() => {
      fetchData()
    })

    return {
      productIdRef, currentPage, perPageOptions, searchQuery, sortBy, perPage, isSortDirDesc, fetchData, list, dataMeta, totalElements,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .program-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

